import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import Style1 from "../change/apps/style1/Style1";
import Template11Form from "../change/apps/template11/Template11Form";
import {WithChildren} from "../../_metronic/helpers";

const PublicRoutes = () => {
  const Converts = lazy(() => import('../change/apps/converts/Converts'))
  const Slider = lazy(() => import('../change/apps/slider/Slider'))
  const Style1 = lazy(() => import('../change/apps/style1/Style1'))
  const Style2 = lazy(() => import('../change/apps/style2/Style2'))
  const Template4 = lazy(() => import('../change/apps/template4/Template4'))
  const Template5 = lazy(() => import('../change/apps/template5/Template5'))
  const Template6 = lazy(() => import('../change/apps/template6/Template6'))
  const Template7 = lazy(() => import('../change/apps/template7/Template7'))
  const Template8 = lazy(() => import('../change/apps/template8/Template8'))
  const Template9 = lazy(() => import('../change/apps/template9/Template9'))
  const Template10 = lazy(() => import('../change/apps/template10/Template10'))
  const Template11 = lazy(() => import('../change/apps/template11/Template11'))
  const Template11Form = lazy(() => import('../change/apps/template11/Template11Form'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='template-1/:widgetId'
          element={
            <SuspensedView>
              <Slider/>
            </SuspensedView>
          }
        />
        <Route
          path='template-2/:widgetId'
          element={
            <SuspensedView>
              <Style1/>
            </SuspensedView>
          }
        />
        <Route
          path='template-3/:widgetId'
          element={
            <SuspensedView>
              <Style2/>
            </SuspensedView>
          }
        />
        <Route
          path='template-4/:widgetId'
          element={
            <SuspensedView>
              <Template4/>
            </SuspensedView>
          }
        />
        <Route
          path='template-5/:widgetId'
          element={
            <SuspensedView>
              <Template5/>
            </SuspensedView>
          }
        />
        <Route
          path='template-6/:widgetId'
          element={
            <SuspensedView>
              <Template6/>
            </SuspensedView>
          }
        />
        <Route
          path='template-7/:widgetId'
          element={
            <SuspensedView>
              <Template7/>
            </SuspensedView>
          }
        />
        <Route
          path='template-8/:widgetId'
          element={
            <SuspensedView>
              <Template8/>
            </SuspensedView>
          }
        />
        <Route
          path='template-9/:widgetId'
          element={
            <SuspensedView>
              <Template9/>
            </SuspensedView>
          }
        />
        <Route
          path='template-10/:widgetId'
          element={
            <SuspensedView>
              <Template10/>
            </SuspensedView>
          }
        />
        <Route
          path='template-11/:widgetId'
          element={
            <SuspensedView>
              <Template11/>
            </SuspensedView>
          }
        />
        <Route
          path='template-11-form/:companyId'
          element={
            <SuspensedView>
              <Template11Form/>
            </SuspensedView>
          }
        />
        <Route
          path='converts/*'
          element={
            <SuspensedView>
              <Converts/>
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PublicRoutes}
