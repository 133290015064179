import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
// import clsx from 'clsx'
// import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from "../../helpers";

const Content: React.FC<WithChildren> = ({children}) => {
  // const {classes} = useLayout()
  //className={clsx(classes.contentContainer.join(' '))}
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div id='happer_content_container' className='container-xxl'>
      {children}
    </div>
  );
}

export {Content}
