import {createRoot} from 'react-dom/client';
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/google-sans/google-sans.scss"; // Standard version
import './_metronic/assets/sass/style.scss'
import './app/change/assets/sass/style.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {setupAxios} from './app/change/auth'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient()

let parentDomain = {
  "ancestorOrigins": {},
  "href": "",
  "origin": "",
  "protocol": "",
  "host": "",
  "hostname": "",
  "port": "",
  "pathname": "",
  "search": "",
  "hash": "",
  "productName":"",
  "productImageLink":"",
}

window.addEventListener('message', (event) => {
  const message = event.data;
  if ((typeof message === 'string') && message.includes('location')) {
    const data = JSON.parse(message);
    parentDomain = data.data;
  }
});

export {parentDomain};

const container: any = document.getElementById('happer-root');
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <AppRoutes/>
    </MetronicI18nProvider>
    {/*<ReactQueryDevtools initialIsOpen={false}/>*/}
  </QueryClientProvider>
)